import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import RobotTableItem from "@/entity/module/RobotTableItem";
import API from "@/api/module/robot";
import OrgAPI from "@/api/system/org";
import SelectLevel from "@/modules/select-level/select-level.vue";
import CockpitCodeAPI from "@/api/project/cockpit-code";
import DictionaryAPI from "@/api/system/dictionary";

@Options({
    name: "app-module-robot",
    components: {
        "app-select-level": SelectLevel,
    },
})
export default class Robot extends BaseTableMixins<RobotTableItem> implements Partial<BaseTableInterface<RobotTableItem>> {
    public enableOrgCode = true;
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new RobotTableItem();
        this.editModalItem = new RobotTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgCode: {
                type: "router",
                name: "org_code",
                title: "所属组织",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.orgCodeLevelModalOpen,
            },
            cockpitCode: {
                type: "eq",
                name: "project_id",
                title: "驾驶舱编号",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.cockpitCodeLevelModalOpen,
            },
            layerId: {
                type: "eq",
                code: "layerId",
                name: "layer_id",
                title: "设备层号",
                parent: 89,
                value: null,
                openFn: this.dictionaryModalOpen,
            },
        };
    }
    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //OrgAPI
    get getOrgApi(): any {
        return OrgAPI;
    }

    //ProjectAPI
    get getCockpitCodeAPI(): any {
        return CockpitCodeAPI;
    }

    //DictionaryAPI
    get getDictionaryAPI(): any {
        return DictionaryAPI;
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new RobotTableItem();
        this.editModalItem = new RobotTableItem();
        this.enableOrgCode = this.editModalItem.projectId === -1;
        this.editModalItem.robotStatus = 1;
        this.editModalTitle = "添加机器人";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "所属网点不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.projectId)) return "所属设备不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.layerId)) return "层号不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.robotFrom)) return "机器人来源不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.robotAccessToken)) return "机器人AccessToken不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.robotKeyword)) return "机器人关键词不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.robotStatus)) return "机器人状态不能为空！";
        return null;
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改批次 (" + this.editModalItem.robotFrom + ")";
        this.editModalSubmitButtonName = "修改";
        this.enableOrgCode = this.editModalItem.projectId === -1;
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.robotId)) return "机器人ID不能为空！";
        return this.addSubmitChecking();
    }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除该机器人信息",
            message: "机器人所在群聊: " + this.editModalItem.robotFrom + "<br>请确认删除？",
        };
    }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "机器人排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "机器人状态",
            message: this.editModalItem.robotFrom + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.robotStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.robotStatus = this.editModalItem.robotStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.robotId)) return "批次ID不能为空！";
        return null;
    }

    //orgCode搜索字段
    private orgCodeLevelSearchField: any = [
        { field: "org_code", name: "代码" },
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgCode层级选择器打开
    public orgCodeLevelModalOpen() {
        (this.$refs.orgCodeLevel as any).openModal();
    }
    //orgCode层级选择器提交
    public async orgCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgCode : item;
        this.tableRequestItem.lockItem.orgCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //cockpitCode搜索字段
    private cockpitCodeLevelSearchField: any = [
        { field: "cockpit_code", name: "代码" },
        { field: "project_name", name: "名称" },
        { field: "project_remark", name: "说明" },
    ];
    //cockpitCode层级选择器打开
    public cockpitCodeLevelModalOpen() {
        (this.$refs.cockpitCodeLevel as any).openModal();
    }
    //cockpitCode层级选择器提交
    public async cockpitCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().cockpitCode : item;
        this.tableRequestItem.lockItem.cockpitCode.value = curItem.value;
        this.tableRequestItem.lockItem.cockpitCode.title = curItem.title;
        this.tableRequestItem.lockItem.cockpitCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //dictionary搜索字段
    private currentLockCode = "layerId";
    private dictionarySearchField: any = [
        { field: "dictionary_code", name: "代码" },
        { field: "dictionary_name", name: "名称" },
        { field: "dictionary_remark", name: "说明" },
    ];
    //dictionary层级选择器打开
    public dictionaryModalOpen(lockCode: string) {
        this.currentLockCode = lockCode;
        this.$nextTick(() => {
            (this.$refs.dictionaryLevel as any).openModal();
        }).then();
    }
    //dictionary层级选择器提交
    public async dictionaryFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem()[this.currentLockCode] : item;
        this.tableRequestItem.lockItem[this.currentLockCode].value = curItem.value;
        this.tableRequestItem.lockItem[this.currentLockCode].title = curItem.title;
        this.tableRequestItem.lockItem[this.currentLockCode].parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get orgCodeSelectApi() {
        return {
            api: (query: any) => OrgAPI.idSelect(query),
            parent: "org_parent_code",
            status: "org_status",
            name: "org_name",
            id: "org_code",
            extend: "全部",
            cid: this.editModalItem.orgCode,
        };
    }

    get cockpitCodeSelectApi() {
        return {
            api: (query: any) => CockpitCodeAPI.cockpitCodeSelect(query),
            parent: "org_code",
            status: "project_status",
            name: "cockpit_code",
            id: "project_id",
            extend: "全部",
            cid: this.editModalItem.projectId,
        };
    }

    public cockpitCodeChange(obj: any) {
        this.editModalItem.orgCode = obj.parent;
        this.enableOrgCode = obj.value === -1;
    }
}
