export default class RobotTableItem {
    robotId = 0;
    robotStatus = 0;
    orgCode = -1;
    projectId = -1;
    layerId = -1;
    robotFrom = "";
    robotAccessToken = "";
    robotKeyword = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";

    orgCodeName = "";
    layerIdName = "";
}
